/* eslint-disable no-console */

import {PersistableLogger} from './PersistableLogger';
import MultichannelSdk from '@multichannel/sdk';
import {EVENT_LIST} from '@multichannel/sdk/src/api/constants';
import Compareable from '@multichannel/sdk/src/Comparable';
import moment from 'moment';
import {LOG_START_TIME, pad} from './Constants';
import CircularJSON from 'circular-json';
import FileSaver from 'file-saver';
import Events from '@core/events';
import Storage from '@core/storage';
import {ScopedLogger} from './ScopedLogger';
import {LogLevel} from './LogLevel';

export class DownLoadableFileLogger extends Compareable implements PersistableLogger {
  _api: MultichannelSdk;
  _logger: ScopedLogger;
  _translations: Record<string, string>;
  _firstLog: Record<string, { b: any[] | {}; s: string; l: LogLevel; m: string }>;
  _firstLogSize: number;
  _rotationLog: Record<string, { b: any[] | {}; s: string; l: LogLevel; m: string }>;
  _rotationLogSize: number;
  _logfileStart: moment.Moment;

  constructor(private _storageKey?: string) {
    super();
    this._api = null;

    this._firstLogSize = 1000;
    this._firstLog = {};

    this._rotationLogSize = 7000;
    this._rotationLog = {};

    this._logfileStart = moment(LOG_START_TIME);

    this._translations = {
      'Enter the password'                           : 'Enter the password',
      'The password you have entered is not correct.': 'The password you have entered is not correct.',
    };
  }

  /**
   * Prompts the user with a password form and downloads the logfile once input is valid.
   */
  downloadLog(password: string = ''): boolean {
    this._logger.traceCall('downloadLog');

    const hasUserAuthorizedBefore = Storage.get('authorized', 'logger');

    if (
      typeof process !== 'undefined' &&
      process.versions &&
      process.versions.hasOwnProperty('electron')
    ) {
      // @todo Electron does not support prompt(), need to implement an alternative someday.
      this._logger.trace('Embedded in electron, not asking for password');
    } else if (!hasUserAuthorizedBefore) {
      const dateObject = new Date();
      const dayAndMonth = pad(dateObject.getDate()) + '' + pad(dateObject.getMonth() + 1);

      if (password !== dayAndMonth) {
        if (password) {
          this._logger.debug('Incorrect logfile password entered, rejecting download', {
            expected: dayAndMonth,
            entered : password,
          });
        }

        Events.trigger(EVENT_LIST.SDK_LOGGER_UNAUTHORIZED);

        return false;
      }

      // Entered password is correct, store that for later so we don't have to bug the user again.
      Storage.set('authorized', true, 'logger');

    } else {
      this._logger.trace('Reusing prior authorization, not asking for password');
    }

    this._logger.trace('Downloading logfile');

    let i13n;

    if (typeof Intl !== undefined) {
      const intl = Intl.DateTimeFormat().resolvedOptions();
      i13n = {
        locale  : intl.locale,
        timezone: intl.timeZone,
      };
    }

    const logStart = this._logfileStart || moment(LOG_START_TIME);
    const logEnd = moment();
    const runtime = moment.utc(moment.duration(logEnd.diff(logStart)).as('milliseconds'));
    const format = 'YYYY-MM-DD HH:mm:ss:SSS';

    const logFileNameFormat = 'YYYY-MM-DD[_]HHmmss';
    const logFileCustomerId = this._api.config?.customerId || '';
    const logFileAgentId = this._api.currentUser?.id || '0';

    const logName = `${logEnd.format(logFileNameFormat)}_${logFileCustomerId}_${logFileAgentId}.json.asc`;

    try {
      const content = CircularJSON.stringify({
        api: {
          buildTime   : this._api.buildInfo.buildTime,
          buildVersion: this._api.buildInfo.buildVersion,
          started     : logStart.format(format),
          stopped     : logEnd.format(format),
          runtime     : runtime.format('HH:mm:ss:SSS'),
          performance : this._api.performanceInfo,
        },
        agentservertoken: sessionStorage.getItem('agentservertokens'),
        tabInstances    : {
          tabInstanceId  : this._api.instanceId,
          tabInstancePos : this._api.instancePosition,
          tabInstanceList: this._api.instanceList
        },
        navigator: {
          vendor       : navigator.vendor,
          appCodeName  : navigator.appCodeName,
          appName      : navigator.appName,
          appVersion   : navigator.appVersion,
          userAgent    : navigator.userAgent,
          language     : navigator.language,
          languages    : navigator.languages,
          platform     : navigator.platform,
          cookieEnabled: navigator.cookieEnabled,
        },
        i13n,
        screen: {
          width          : screen.width,
          height         : screen.height,
          availableWidth : screen.availWidth,
          availableHeight: screen.availHeight,
        },
        missingTranslations: this._api.language.missingTranslations,
        firstLog           : this._firstLog,
        rotationLog        : this._rotationLog,
      });

      if (this._api.config?.flags?.disableEncryption) {
        FileSaver.saveAs(
          new Blob(
            [
              content,
            ],
            { type: 'text/plain;charset=utf-8' },
          ),
          logName,
        );
      } else {
        this._api.encryption.encrypt(content).then(encrypted => {
          FileSaver.saveAs(
            new Blob(
              [
                encrypted,
              ],
              { type: 'text/plain;charset=utf-8' },
            ),
            logName,
          );
        }).catch(err => {
          console.error('Could not download logfile');
          console.error(err);
        });
      }

    } catch (e) {
      console.error('Could not download logfile');
      console.error(e);
    }

    return true;
  }

  log(message: string, relatedInfo: any[] | {}, level: LogLevel, scope: string) {
    const s = scope;
    const l = level;
    const m = message;
    let b = relatedInfo;
    if (relatedInfo) {
      if (
        (typeof relatedInfo === 'object' && !Object.keys(relatedInfo).length) ||
        (Array.isArray(relatedInfo) && !relatedInfo.length)
      ) {
        // Lets not spam the logfile with empty infos.
        b = undefined;
      }
    }

    // If any param is undefined it will not be included in the log message.
    const messageObject = { s, l, m, b };

    const firstLogKeys = Object.keys(this._firstLog);
    const firstLogLength = firstLogKeys.length;
    const rotationLogKeys = Object.keys(this._rotationLog);
    const rotationLogLength = rotationLogKeys.length;
    const timestamp = moment().format('HH:mm:ss:SSS');

    // Creates a unique key for the next entry, based on the timestamp.
    // If by chance another entry has been pushed in the exact same millisecond we simply append the iterations
    // needed to reach uniqueness.
    const generateKey = (log, iteration = 0) => {
      const key = timestamp + (iteration > 0 ? ' ' + iteration : '');
      if (log[key]) return generateKey(log, iteration + 1);
      return key;
    };

    if (firstLogLength < this._firstLogSize) {
      // Fill up the first log.
      this._firstLog[generateKey(this._firstLog)] = messageObject;
    } else {
      // First log is already full, use the rotation log.
      this._rotationLog[generateKey(this._rotationLog)] = messageObject;

      if (rotationLogLength > this._rotationLogSize) {
        // Make sure the rotation log does not exceed the size limit.
        // Shift the log by removing the first entry.
        delete this._rotationLog[rotationLogKeys[0]];
      }
    }
  }

  setApi(api: MultichannelSdk) {
    this._api = api;

    this._logger = this._api.debug('api.logger');
  }

  persist() {
    Storage.set(this._storageKey, {firstLog: this._firstLog, rotationLog: this._rotationLog}, 'logger');
  }

  restore() {
    try {
      const {firstLog, rotationLog} = Storage.getAndRemove(this._storageKey, 'logger');
      this._firstLog = firstLog;
      this._rotationLog = rotationLog;
    } catch {
      this._logger.trace('Couldn\'t restore logger data');
    }
  }
}