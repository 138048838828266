/* eslint-disable no-console */
// noinspection JSValidateJSDoc

import MultichannelSdk from '@multichannel/sdk';
import { EVENT_LIST } from '@multichannel/sdk/src/api/constants';
import {isPersistableLogger, PersistableLogger} from './PersistableLogger';
import {ConsoleLogger} from './ConsoleLogger';
import {LOG_LEVEL_DEBUG, LOG_LEVEL_ERROR, LOG_LEVEL_TRACE, LOG_LEVEL_WARN, LogLevel} from './LogLevel';
import {DownLoadableFileLogger} from './DownloadableFileLogger';
import {ScopedLogger} from './ScopedLogger';
import {TRACE_CALL, TRACE_CONSTRUCT} from './Constants';
import Events from '@core/events';
import Actions from '@core/actions';
import Storage from '@core/storage';

export class LogService implements PersistableLogger {
  private readonly _api: MultichannelSdk;
  private _debugScope: string[];
  _logger: (ScopedLogger | ConsoleLogger)[];
  _messageCache: IArguments[];
  _originalConsoleObject: typeof console;

  constructor(api: MultichannelSdk, logger?: ScopedLogger[]) {

    /**
     * A list of available loggers.
     */
    this._logger = [];

    this._api = api;

    /**
     * Storage for messages that could not be sent to any logger.
     */
    this._messageCache = [];

    this._debugScope = Storage.get('debug', 'logger') || [];

    if (logger) {
      if (!(logger instanceof Array)) {
        logger = [logger];
      }

      for (const singleLogger of logger) {
        this.setLogger(singleLogger);
      }
    }
    if (process.env.NODE_ENV === 'production' && !this._api.debugMode) {
      this._overwriteConsoleObject();
    } else {
      this.setLogger(new ConsoleLogger());
    }

    this.registerKeyCombination();
  }

  registerActions() {
    Actions.register('sdk.logger.download', password => {
      return this.downloadLog(password);
    });
    Actions.register('sdk.logger.persist', () => {
      this.persist();
    });
    Events.once(EVENT_LIST.SDK_ME_AFTER_RESET, () => {
      this.persist();
    });
  }

  _overwriteConsoleObject() {
    this._originalConsoleObject = console;

    // Stop information leaking to the console.
    //@ts-ignore
    window.console = {
      log  : (...params) => this.trace(typeof params[0] === 'string' ? params[0] : undefined, params.slice(typeof params[0] === 'string' ? 1 : 0), 'window.console.log'),
      info : (...params) => this.trace(typeof params[0] === 'string' ? params[0] : undefined, params.slice(typeof params[0] === 'string' ? 1 : 0), 'window.console.info'),
      debug: (...params) => this.debug(typeof params[0] === 'string' ? params[0] : undefined, params.slice(typeof params[0] === 'string' ? 1 : 0), 'window.console.debug'),
      warn : (...params) => this.debug(typeof params[0] === 'string' ? params[0] : undefined, params.slice(typeof params[0] === 'string' ? 1 : 0), 'window.console.warn'),
      error: (...params) => this.error(typeof params[0] === 'string' ? params[0] : undefined, params.slice(typeof params[0] === 'string' ? 1 : 0), 'window.console.error'),
    };
  }

  _restoreConsoleObject() {
    if (!this._originalConsoleObject) {
      // Nothing to revert, do not mess up.
      return;
    }
    //@ts-ignore
    window.console = this._originalConsoleObject;
  }

  addDebugScope = (scope: string[] | string) => {
    if (!Array.isArray(scope)) scope = [scope];

    this._debugScope = [...this._debugScope, ...scope];
    Storage.set('debug', this._debugScope, 'logger');

    return this._debugScope;
  };

  clearDebugScope = () => {
    Storage.remove('debug', 'logger');
    this._debugScope = [];
  };

  registerKeyCombination() {
    document.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        if (e.keyCode === 76 /*L*/ && e.ctrlKey && e.shiftKey && e.altKey) {
          Actions.invoke('sdk.logger.download');
        }
      },
      false,
    );
  }

  /**
   * Logs a message with the log level ERROR on all registered loggers.
   */
  debug(message: string | {}, relatedInfo: {} = {}, scope: string | null) {
    this.log(message, relatedInfo, LOG_LEVEL_DEBUG, scope);
  }

  /**
   * Initiates a logfile download on those registered loggers that support it.
   */
  downloadLog(password: string = '') {
    this.scoped('api.logger').traceCall('downloadLog');
    let downloadSuccess = false;

    for (const logger of this._logger) {
      if (logger instanceof DownLoadableFileLogger && typeof logger.downloadLog !== 'undefined') {
        if (logger.downloadLog(password)) {
          downloadSuccess = true;
        }
      }
    }

    return downloadSuccess;
  }

  /**
   * Logs a message with the log level ERROR on all registered loggers.
   */
  error(message: string | {}, relatedInfo: {} = {}, scope: string | null) {
    let useRelatedInfo = relatedInfo;
    // If we got an error , convert it to a normal object
    if(relatedInfo instanceof Error) {
      useRelatedInfo = Object.getOwnPropertyNames(relatedInfo)
        .reduce((obj, propName) => {
          obj[propName] = relatedInfo[propName];
          return obj;
        }, { name: relatedInfo.name });
    }
    this.log(message, { ...useRelatedInfo }, LOG_LEVEL_ERROR, scope);
  }

  warning(message, relatedInfo = {}, scope) {
    this.log(message, { ...relatedInfo }, LOG_LEVEL_WARN, scope);
  }

  /**
   * This is called by the SDK *after* it has been constructed.
   * Therefore we can use this method to register events or other things dependent on submodules.
   */
  init() {
    // Make sure to enable / disable console logging when needed.
    Events.on(EVENT_LIST.SDK_DEBUG_MODE_ENABLED, () => this._restoreConsoleObject());
    Events.on(EVENT_LIST.SDK_DEBUG_MODE_DISABLED, () => this._overwriteConsoleObject());

    Events.on(EVENT_LIST.SDK_ME_BEFORE_RESET, () => {
      const debugScope = Storage.get('debug', 'logger') || [];
      Events.once(EVENT_LIST.SDK_ME_AFTER_RESET, () => {
        this.setDebugScope(debugScope);
      });
    });

    // Make sure we can properly log Error objects.
    if (!('toJSON' in Error.prototype)) {
      Object.defineProperty(Error.prototype, 'toJSON', {
        value() {
          const alt = {
            errorType: this.constructor.name,
          };

          Object.getOwnPropertyNames(this).forEach(function (key) {
            alt[key] = this[key];
          }, this);

          return alt;
        },
        configurable: true,
        writable    : true,
      });
    }
  }

  /**
   * Logs a message with the given level on all registered loggers.
   */
  log(message: string | {}, relatedInfo: {} = {}, level: LogLevel = LOG_LEVEL_TRACE, scope: string = 'unknown') {
    if (this._debugScope.find(s => scope.startsWith(s))) {
      console.group(
        `%c ${scope}-${level}`,
        // tslint:disable-next-line:max-line-length
        'background-color: #fff;border-color: #b3d4ff; color: #0052cc;background: #dfe1e6;border: 1px solid #dfe1e6; border-radius: 3px; display: inline-block; font-size: 11px; font-weight: bold; line-height: 99%; margin: 0; padding: 2px 5px; text-align: center; text-decoration: none; text-transform: uppercase; ',
      );
      if (message) console.warn(`%c ${message}`, 'color:#990000; font-size:15px;');
      if (relatedInfo) console.log(relatedInfo);
      console.groupEnd();
    }
    if (!this._logger.length) {
      this._messageCache.push(arguments);
    } else {
      this._logger.forEach(logger => {
        logger.log(message, relatedInfo, level, scope);
      });
    }
  }

  /**
   * Registers an error handler for the current window to prevent any of our errors leaking
   * to the browser console. We log them into our own logfile, that is sufficient.
   */
  registerFunctionToCatchUncaughtJSErrors() {
    const transformStack = errorObject =>
      errorObject && errorObject.stack
        ? errorObject.stack.split('\n').reduce((result, item, index) => {
          result[index] = item.trim();
          return result;
        }, {})
        : {};

    global.addEventListener('error', (event) => {
      this.scoped('browser.onerror').error(event.message, {
        url         : event.filename,
        lineNumber  : event.lineno,
        columnNumber: event.colno,
        stack       : transformStack(event.error),
      });
      return process.env.NODE_ENV === 'production';
    });
    global.addEventListener('unhandledrejection', event => {
      this.scoped('browser.unhandledRejection').error(
        event.reason?.message || 'undefined',
        transformStack(event.reason),
      );
      if (process.env.NODE_ENV === 'production') {
        event.preventDefault();
      }
    });
  }
  removeDebugScope = (scope: string) => {
    this._debugScope = [...this._debugScope.filter(s => s !== scope)];
    Storage.set('debug', this._debugScope, 'logger');
    return this._debugScope;
  };

  /**
   * Returns a scoped interface of the logger.
   *
   * This enables easy access to log methods which will keep the provided scope.
   * The scopeName will be logged along with every message.
   */
  scoped(scopeName: string): ScopedLogger {
    return {
      error    : (message, relatedInfo = {}) => this.error(message, relatedInfo, scopeName),
      warning  : (message, relatedInfo = {}) => this.warning(message, relatedInfo, scopeName),
      debug    : (message, relatedInfo = {}) => this.debug(message, relatedInfo, scopeName),
      trace    : (message, relatedInfo = {}) => this.trace(message, relatedInfo, scopeName),
      traceCall: (methodName, parameters = null) =>
        this.traceCall(methodName, parameters, scopeName),
      traceConstruct: (className, parameters = null) =>
        this.traceConstruct(className, parameters, scopeName),
    };
  }

  setDebugScope = (scope: string[]) => {
    this._debugScope = scope;
    Storage.set('debug', this._debugScope, 'logger');

    return this._debugScope;
  };

  private hasSetApi(logger: ScopedLogger | ConsoleLogger | ScopedLogger & {setApi: (MultichannelSdk) => void | undefined}): logger is ScopedLogger & {setApi: (MultichannelSdk) => void | undefined} {
    return typeof (logger as ScopedLogger & {setApi: (MultichannelSdk)}).setApi !== 'undefined';
  }

  /**
   * Sets a new logger to be used by the LogService.
   */
  setLogger(logger: ScopedLogger | ConsoleLogger | ScopedLogger & {setApi: (MultichannelSdk) => void | undefined}) {
    if (this.hasSetApi(logger)) {
      logger.setApi(this._api);
    }

    this._logger.push(logger);

    if (this._messageCache.length) {
      for (const item of this._messageCache) {
        logger.log.apply(item);
      }
      this._messageCache = [];
    }
  }

  /**
   * Logs a message with the log level TRACE on all registered loggers.
   */
  trace(message: string | {}, relatedInfo = {}, scope: string | null) {
    this.log(message, relatedInfo, LOG_LEVEL_TRACE, scope);
  }

  /**
   * Convenience method to log a standardized message for method invocation.
   *
   */
  traceCall(methodName: string | {}, parameters = {}, scope: string | null) {
    if (scope) {
      // Append the methodName to the scope to keep the logfile compact.
      // Resulting in "api.auth.setAuthenticationMethod()", for example.
      return this.log(undefined, parameters, LOG_LEVEL_TRACE, scope + '.' + TRACE_CALL(methodName));
    }

    this.log(TRACE_CALL(methodName), parameters, LOG_LEVEL_TRACE, scope);
  }

  /**
   * Convenience method to log a standardized message for object instantiation.
   *
   * @param {string} className
   * @param {{}} [parameters]
   * @param {string|null} [scope]
   */
  traceConstruct(className: string | {}, parameters = {}, scope: string | null) {
    this.log(TRACE_CONSTRUCT(className), parameters, LOG_LEVEL_TRACE, scope);
  }

  /**
   * Convenience method to log a list of parameters.
   *
   * @deprecated Use trace() instead and provide a meaningful message.
   */
  traceParams() {
    this.log(
      '[trace_params_without_message]',
      arguments,
      LOG_LEVEL_TRACE,
      typeof arguments[arguments.length - 1] === 'string'
        ? arguments[arguments.length - 1]
        : undefined,
    );
  }

  persist() {
    this._logger.forEach(logger => isPersistableLogger(logger) && logger.persist());
  }

  restore() {
    this._logger.forEach(logger => isPersistableLogger(logger) && logger.restore());
  }
}