export default {
  VERSION          : '24.4',
  INTERFACE_VERSION: 21,
  LOGIN_TIMEOUT    : 30,
  PLUGIN_TIMEOUT   : 5000,
};

export const EVENT_LIST = {
  SDK_DEBUG_MODE_ENABLED                         : 'sdk.debug-mode.enabled',
  SDK_DEBUG_MODE_DISABLED                        : 'sdk.debug-mode.disabled',
  SDK_ACTIONS_BEFORE                             : 'sdk.actions.before',
  SDK_ACTIONS_AFTER                              : 'sdk.actions.after',
  SDK_CLOSING_REQUESTED                          : 'sdk.closing-requested',
  SDK_CONNECT_ERROR                              : 'sdk.connect.error',
  SDK_ME_AUTHENTICATING                          : 'sdk.me.authenticating',
  SDK_ME_LOGIN_ERROR                             : 'sdk.me.login.error',
  SDK_ME_LOGIN_SUCCESS                           : 'sdk.me.login.success',
  SDK_ME_BEFORE_RESET                            : 'sdk.me.before-reset',
  SDK_ME_AFTER_RESET                             : 'sdk.me.after-reset',
  SDK_ME_LOGOUT                                  : 'sdk.me.logout',
  SDK_ME_CALL_DIALOUT_ERROR                      : 'sdk.me.call.dialout.error',
  SDK_ME_CALL_WARM_TAKEOVER_ERROR                : 'sdk.me.call.warm.takeover.error',
  SDK_ME_CALL_ADD_CALL_REASON                    : 'sdk.me.call.add-call-reason',
  SDK_ME_CALL_MUTE_STATE_CHANGED                 : 'sdk.me.call.mute-state-changed',
  SDK_ME_CALL_RECORDING                          : 'sdk.me.call.recording',
  SDK_ME_CALL_XPATH                              : 'sdk.me.call.xpath',
  SDK_ME_CALL_END                                : 'sdk.me.call.end',
  SDK_ME_CALL_CALLER_QUITS                       : 'sdk.me.call.caller.quits',
  SDK_ME_CALL_FINISHED                           : 'sdk.me.call.finished',
  SDK_ME_CALL_MISSED                             : 'sdk.me.call.missed',
  SDK_ME_CALL_REMOVE_CALL_REASON                 : 'sdk.me.call.remove-call-reason',
  SDK_ME_CALL_LOG_TARGETS_UPDATED                : 'sdk.me.call.log-targets.updated',
  SDK_ME_CALL_NOTES_RECEIVED                     : 'sdk.me.call.notes.received',
  SDK_ME_CALL_RELEVANTOBJECTS_UPDATED            : 'sdk.me.call.relevantObjects.updated',
  SDK_ME_CALL_RELEVANTOBJECTS_REQUESTED          : 'sdk.me.call.relevantObjects.requested',
  SDK_ME_CALL_RELEVANTOBJECTS_SELECTED           : 'sdk.me.call.relevantObjects.selected',
  SDK_ME_CALL_NONVOICE_DECLINED                  : 'sdk.me.call.non-voice.declined',
  SDK_ME_WORKITEM                                : 'sdk.me.work-item',
  SDK_ME_WORKITEM_NEW                            : 'sdk.me.work-item.new',
  SDK_ME_WORKITEM_SEND                           : 'sdk.me.work-item.send',
  SDK_ME_WORKITEM_CANCELED                       : 'sdk.me.work-item.canceled',
  SDK_ME_WORKITEM_EXTENDED                       : 'sdk.me.work-item.extended',
  SDK_ME_WORKITEM_STARTED                        : 'sdk.me.work-item.started',
  SDK_ME_WORKITEM_STOPPED                        : 'sdk.me.work-item.stopped',
  SDK_ME_WORKITEM_TRANSFERED                     : 'sdk.me.work-item.transfered',
  SDK_ME_WORKITEM_ERROR                          : 'sdk.me.work-item.error',
  SDK_ME_WORKITEM_DEFERED                        : 'sdk.me.work-item.defered',
  SDK_ME_WORKITEM_DROP                           : 'sdk.me.current-workitem.drop',
  SDK_ME_GROUPS_CONFIGRECEIVED                   : 'sdk.me.groups.configReceived',
  SDK_ME_GROUPS_FILTERED                         : 'sdk.me.groups.filtered',
  SDK_ME_GROUPS_BLOCKED_RECIEVED                 : 'sdk.me.groups.blocked.recieved',
  SDK_ME_COACHING_CONNECT                        : 'sdk.me.coaching.connect',
  SDK_ME_COACHING_DISCONNECT                     : 'sdk.me.coaching.disconnect',
  SDK_ME_COACHING_MONITORING_REQUEST             : 'sdk.me.coaching.monitoring.request',
  SDK_ME_COACHING_MONITORING_ACCEPTED            : 'sdk.me.coaching.monitoring.accepted',
  SDK_ME_COACHING_MONITORING_DISCONNECT          : 'sdk.me.coaching.monitoring.disconnect',
  SDK_ME_CHAT_INCOMING_MESSAGE                   : 'sdk.me.chat.incoming-message',
  SDK_ME_CONFIG_RECEIVED                         : 'sdk.me.config.received',
  SDK_ME_STATUS_PAUSE_REASON_CHANGED             : 'sdk.me.status.pause-reason.changed',
  SDK_ME_STATUS_NEW                              : 'sdk.me.status.new',
  SDK_ME_STATUS_CALL_BEGIN                       : 'sdk.me.status.call-begin',
  SDK_ME_STATUS_CALL_END                         : 'sdk.me.status.call-end',
  SDK_ME_TICK                                    : 'sdk.me.tick',
  SDK_ME_UMS_STATUS                              : 'sdk.me.ums-status',
  SDK_ME_SIDE_BY_SIDE_COACHING_STATE_CHANGED     : 'sdk.me.side-by-side-coaching-state-changed',
  SDK_ME_CALL_DROP                               : 'sdk.me.call.drop',
  SDK_ME_TEXT_INFO_RECEIVED                      : 'sdk.me.text-info.received',
  SDK_ME_USERCONFIG_RECEIVED                     : 'sdk.me.userconfig.received',
  SDK_ME_TEXT_INFO_CLEARED                       : 'sdk.me.text-info.cleared',
  SDK_AUTH_SESSIONCONTINUE                       : 'sdk.auth.sessioncontinue',
  SDK_AUTH_RECEIVED_SESSION_CONTINUE_RESULT      : 'sdk.auth.received-session-continue-result',
  SDK_RESPONDING_CLEAR                           : 'sdk.responding.clear',
  SDK_RESPONDING_ERROR                           : 'sdk.responding.error',
  SDK_CALLS_HISTORY_UPDATED                      : 'sdk.calls.history.updated',
  SDK_VOICE_OUTBOUND_NEW                         : 'sdk.voice.outbound.new',
  SDK_CONTACT_HISTORY_UPDATED                    : 'sdk.contact-history.updated',
  SDK_MESSAGE_ACD_TRANSIT_ACTION_SWITCH_WORKSPACE: 'sdk.message.acd-transit-action-switch-workspace',
  SDK_WORKITEMS_PICKABLE_FAILED                  : 'sdk.workitems.pickable.failed',
  SDK_WORKITEMS_PICKABLE_SUCCESFULL              : 'sdk.workitems.pickable.succesfull',
  SDK_WORKITEMS_SET_RESUBMISSION_FAILED          : 'sdk.workitems.set_resubmission.failed',
  SDK_CALLS_PICKABLE_SUCCESFULL                  : 'sdk.calls.pickable.succesfull',
  SDK_CALLS_PICKABLE_FAILED                      : 'sdk.calls.pickable.failed',
  SDK_CALLS_WRONG_NUMBER                         : 'sdk.calls.wrong_number',
  SDK_AGENT_MONITOR_CONFIGURATION                : 'sdk.agent-monitor.configuration',
  SDK_AGENT_MONITOR_DATA                         : 'sdk.agent-monitor.data',
  SDK_CALLS_PICKABLE_UPDATED                     : 'sdk.calls.pickable.updated',
  SDK_WORKITEMS_PICKABLE_UPDATED                 : 'sdk.workitems.pickable.updated',
  SDK_MASTER_DATA_RECEIVED                       : 'sdk.master-data.received',
  SDK_ERROR_ACD_UNRESPONSIVE                     : 'sdk.error.acd-unresponsive',
  SDK_GROUP_MONITOR_DATA                         : 'sdk.group-monitor.data',
  SDK_INITIALIZED                                : 'sdk.initialized',
  SDK_ME_CALL_INCOMING                           : 'sdk.me.call.incoming',
  SDK_ME_CALL_RECORDING_STATES                   : 'sdk.me.call.recording-states',
  SDK_USER_STATISTIC_RAW                         : 'sdk.user-statistic.raw',
  SDK_USER_STATISTIC_DATA                        : 'sdk.user-statistic.data',
  SDK_NETWORK_SOCKET_ONLINE                      : 'sdk.network.socket.online',
  SDK_NETWORK_SOCKET_LOST                        : 'sdk.network.socket.lost',
  SDK_NETWORK_SOCKET_ERROR                       : 'sdk.network.socket.error',
  SDK_NETWORK_RECONNECT_MAXIMUM_REACHED          : 'sdk.network.socket.reconnect.maximum.reached',
  SDK_NETWORK_ONLINE                             : 'sdk.network.online',
  SDK_NETWORK_OFFLINE                            : 'sdk.network.offline',
  SDK_NETWORK_HTTP_REQUEST_EXECUTE               : 'sdk.network.http-request.execute',
  SDK_NETWORK_HTTP_REQUEST_NEW                   : 'sdk.network.http-request.new',
  SDK_NETWORK_HTTP_REQUEST_ALL_CLEARED           : 'sdk.network.http-request.all-cleared',
  SDK_VOIP_STATE_CHANGED                         : 'sdk.voip.state-changed',
  SDK_VOIP_REGISTERED                            : 'sdk.voip.registered',
  SDK_VOIP_UNREGISTERED                          : 'sdk.voip.unregistered',
  SDK_VOIP_REGISTRATION_FAILED                   : 'sdk.voip.registation.failed',
  SDK_VOIP_CONNECTING                            : 'sdk.voip.connecting',
  SDK_VOIP_CONNECTED                             : 'sdk.voip.connected',
  SDK_VOIP_DISCONNECTED                          : 'sdk.voip.disconnected',
  SDK_VOICE_ALERTING_START                       : 'sdk.voice.alerting.start',
  SDK_VOICE_ALERTING_END                         : 'sdk.voice.alerting.end',
  SDK_VOIP_ERROR                                 : 'sdk.voip.error',
  SDK_VOIP_NOTIFICATION                          : 'sdk.voip.notification',
  SDK_VOIP_INVITE                                : 'sdk.voip.invite',
  SDK_VOIP_SELFCHECK_RUN                         : 'sdk.voip.selfcheck.run',
  SDK_VOIP_SELFCHECK_FINISHED                    : 'sdk.voip.selfcheck.finished',
  SDK_AUDIO_VOLUME_CHANGE_VOICE                  : 'sdk.audio.volume.change.voice',
  SDK_ISDN_BUSY_INFO                             : 'sdk.isdn.busy-info',
  SDK_LOGGER_UNAUTHORIZED                        : 'sdk.logger.unauthorized'
};

export const BLOATED_EVENTS = [
  'sdk.me.tick',
  'sdk.auth.me.time.tick',
  'onlineMonitor:groupMonitorData',
  'sdk.group-monitor.data',
  'sdk.agent-monitor.data',
  'onlineMonitor:agentMonitorData',
  'error:could not load config',
  'error: no response',
  'clear: got response',
  'sdk.auth.authenticating',
  'login:error',
  'auth:logout',
  'login:error',
  'call:callLogTargets:updated',
  'call:relevantObjects:updated',
  'call:relevantObjects:requested',
  'call:relevantObjects:selected',
  'api.voice.history.updated',
  'multichannel.api.groups.configReceived',
  'user: blocked groups received',
  'groups: groups filtered',
  'onlineMonitor:agentMonitorConfiguration',
  'initialized',
  'user:incomingCall',
  'call:recordingStates',
  'socket:error',
  'api.http-request.execute',
  'httpRequests: new request',
  'httpRequests: requests cleared',
  'user:status',
  'user:status:new',
  'user:status:call:begin',
  'user:status:call:end',
  'user:umsStatus',
  'user.receivedConfig',
  'user: currentCall dropped',
  'user: currentWorkItem dropped',
  'voip:connecting',
  'voip:connected',
  'voip:disconnected',
  'voip:registered',
  'voip:unregistered',
  'voip:registrationFailed',
  'voip:invite',
  'voip:error',
  'user:additionalWorkItemInfo',
  'user:newWorkItem',
  'sdk.work-item',
  'sdk.work-item.new',
  'pcweb.gui.composition.added',
  'sdk.calls.pickable.updated',
  'sdk.workitems.pickable.updated',
];

export const EVENT_LIST_DEPRECATED = [
  'error:could not load config',
  'sdk.auth.authenticating',
  'login:error',
  'clear: got response',
  'error: no response',
  'api.call.mute-state-changed',
  'call.recording',
  'call:xpath',
  'call:end',
  'call:finished',
  'call:callLogTargets:updated',
  'call:relevantObjects:updated',
  'call:relevantObjects:requested',
  'call:relevantObjects:selected',
  'api.voice.history.updated',
  'multichannel.api.groups.configReceived',
  'groups: groups filtered',
  'user: blocked groups received',
  'onlineMonitor:agentMonitorConfiguration',
  'onlineMonitor:agentMonitorData',
  'user:customer config received',
  'onlineMonitor:groupMonitorData',
  'user:incomingCall',
  'call:recordingStates',
  'api.http-request.execute',
  'httpRequests: new request',
  'httpRequests: requests cleared',
  'user:status',
  'user:status:new',
  'user:status:call:begin',
  'user:status:call:end',
  'sdk.auth.me.time.tick',
  'user:umsStatus',
  'user: currentCall dropped',
  'user: currentWorkItem dropped',
  'user: received text info to be processed',
  'user.receivedConfig',
  'voip:registered',
  'voip:unregistered',
  'voip:registrationFailed',
  'voip:connecting',
  'voip:connected',
  'voip:disconnected',
  'voip:error',
  'voip:invite',
  'sdk.work-item.new',
  'user:additionalWorkItemInfo',
  'sdk.work-item',
  'user:newWorkItem',
  'initialized',
  'sdk.work-item.send',
  'sdk.work-item.canceled',
  'sdk.work-item.extended',
  'sdk.work-item.started',
  'sdk.work-item.stopped',
  'sdk.work-item.error',
  'sdk.work-item.defered',
];

export const AGENT_EXT_STATE = {
  IDLE                              : 0,
  IDLE_FORCE                        : 1,
  IDLE_TIME                         : 2,
  PAUSE                             : 3,
  FREE                              : 4,
  FREE_NO_ANSWER                    : 5,
  FREE_BUSY                         : 6,
  AGENT_EXT_FREE_NO_CONNECT         : 7,
  RESERVED                          : 10,
  CALLING                           : 11,
  ALERTING                          : 12,
  WHISPERING                        : 13,
  CONNECTED                         : 14,
  MUTE                              : 20,
  ONHOLD                            : 21,
  AGENT_CALL                        : 22,
  WRAPUP                            : 23,
  CALL_REASONS_PHONE_INPUT          : 24,
  WRAPUP_NO_FORCED_CALL_REASON      : 25,
  WRAPUP_END_NO_FORCED_CALL_REASON  : 26,
  OUTBOUND_AGENT_ALERTING           : 33,
  OUTBOUND_AGENT_CONNECTED          : 34,
  OUTBOUND_CONNECTED                : 35,
  OUTBOUND_NO_CONNECT_BUSY          : 36,
  OUTBOUND_NO_CONNECT_BLACKLIST     : 37,
  OUTBOUND_NO_CONNECT_OTHER         : 38,
  OUTBOUND_NO_CONNECT_INVALID_NUMBER: 39,
  OUTBOUND_NO_CONNECT_NO_ANSWER     : 40,
  OUTBOUND_NO_CONNECT_NO_PERMISSION : 41,
  CONFERENCE                        : 70,
  BLENDING                          : 80,
  AGENT_EXT_BUSY_EXTERNAL_PROCESSING: 81

};

export const AGENT_STATE = {
  IDLE                       : 0,
  FREE                       : 1,
  BUSY                       : 2,
  PAUSE                      : 3,
  BLENDING                   : 4,
  BLENDING_FORCED_AFTER_PAUSE: 5,
  UMS_PROCESSING             : 99 //only used for NextState
};

export const PLUGIN_DEPRECATED = ['pcweb/vertical-workspace-navigation', 'pcweb/coaching', 'multichannel/skype-for-business-adapter'];

export const WHITELIST_SEARCH_PARAMS = [
  'logout'
];