export enum LogLevel {
  DEBUG = 'debug',
  ERROR = 'error',
  INFO = 'info',
  TRACE = 'trace',
  WARN = 'warn',
}

export const LOG_LEVEL_TRACE = LogLevel.TRACE;
export const LOG_LEVEL_DEBUG = LogLevel.DEBUG;
export const LOG_LEVEL_INFO  = LogLevel.INFO;
export const LOG_LEVEL_WARN  = LogLevel.WARN;
export const LOG_LEVEL_ERROR = LogLevel.ERROR;