/* eslint-disable no-console */
export class ConsoleLogger {
  /**
   * Logs to console.
   */
  log(message: string | {}, relatedInfo: any[] | {}, level: string, scope: string) {
    const method = { debug: 'log', trace: 'info', error: 'error' };

    // Only log errors to the console.
    if (level !== 'error') return;

    console[method[level] || 'log']({ scope, level, message, relatedInfo });
  }
}