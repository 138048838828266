import hash from 'object-hash';
import { isObservable, toJS } from 'mobx';
import moment from 'moment';
export default abstract class Compareable {
  toJSON = this.toObject;

  toHash(): string {
    return hash(this.toObject());
  }
  toObject(): {} {
    const protoEntries = Object.getOwnPropertyNames(Object.getPrototypeOf(this));

    const entries = Object.entries(this);

    protoEntries.forEach(property => {
      entries.push([property, this[property]]);
    });
    const filteredEntries = Object.create(null);

    entries.forEach(([key, value]) => {
      if (value === null || value === undefined) return;
      if (typeof value === typeof undefined || typeof value === 'function') return;
      if (key[0] === '_') return;

      if (typeof value.toObject === 'function') {
        try {
          const newValue = value.toObject();
          filteredEntries[key] = newValue;
          return;
        } catch (e) {
          return;
        }
      }
      if (value instanceof moment) {
        //@ts-ignore
        filteredEntries[key] = value.format();
        return;
      }

      if (isObservable(value)) {
        filteredEntries[key] = value.toObject
          ? value.toObject()
          : toJS(value, { detectCycles: true, recurseEverything: false });
      }

      if (value instanceof Object) return;

      filteredEntries[key] = value;
    });

    return Object.assign(Object.create(null), JSON.parse(JSON.stringify(filteredEntries)));
  }
}
