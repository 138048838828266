/* eslint-disable no-console */

import Compareable from '@multichannel/sdk/src/Comparable';
import {PersistableLogger} from './PersistableLogger';
import {LOG_START_TIME, pad} from './Constants';
import MultichannelSdk from '@multichannel/sdk';
import {EVENT_LIST} from '@multichannel/sdk/src/api/constants';
import moment from 'moment';
import CircularJSON from 'circular-json';
import FileSaver from 'file-saver';
import Events from '@core/events';
import Storage from '@core/storage';
import {ScopedLogger} from './ScopedLogger';
import {LogLevel} from './LogLevel';

export class DownloadablePcWinFormatLogger extends Compareable implements PersistableLogger {
  _api : MultichannelSdk;
  _firstLog: string[];
  _firstLogSize: number;
  _logger: ScopedLogger;
  _rotationLog: string[];
  _rotationLogSize: number;
  _translations: Record<string, string>;
  _events: typeof Events;
  _storage: typeof Storage;

  constructor(private _storageKey?: string) {
    super();
    this._api = null;

    this._firstLogSize = 1000;
    this._rotationLogSize = 7000;

    this._firstLog = [];
    this._rotationLog = [];

    //according to https://4com.kanbanize.com/ctrl_board/5/cards/13408/details/
    this._translations = {
      'Enter the password'                           : 'Enter the password',
      'The password you have entered is not correct.': 'The password you have entered is not correct.',
    };
  }

  downloadLog(password: string = ''): boolean {
    this._logger.traceCall('downloadLog');

    const hasUserAuthorizedBefore = Storage.get('authorized', 'logger');

    if (
      typeof process !== 'undefined' &&
      process.versions &&
      process.versions.hasOwnProperty('electron')
    ) {
      // @todo Electron does not support prompt(), need to implement an alternative someday.
      this._logger.trace('Embedded in electron, not asking for password');
    } else if (!hasUserAuthorizedBefore) {

      const dateObject = new Date();
      const dayAndMonth = pad(dateObject.getDate()) + '' + pad(dateObject.getMonth() + 1);

      if (password !== dayAndMonth) {

        if (password) {
          this._logger.debug('Incorrect logfile password entered, rejecting download', {
            expected: dayAndMonth,
            entered : password,
          });
        }

        Events.trigger(EVENT_LIST.SDK_LOGGER_UNAUTHORIZED);

        return false;
      }

      // Entered password is correct, store that for later so we don't have to bug the user again.
      Storage.set('authorized', true, 'logger');

    } else {
      this._logger.trace('Reusing prior authorization, not asking for password');
    }

    this._logger.trace('Downloading logfile');

    let i13n;

    if (typeof Intl !== undefined) {
      const intl = Intl.DateTimeFormat().resolvedOptions();
      i13n = {
        locale  : intl.locale,
        timezone: intl.timeZone,
      };
    }

    const logStart = moment(LOG_START_TIME);
    const logStartFormatted = logStart.format('DD.MM HH:mm:ss.SSS');

    const logEnd = moment();
    const runtime = moment.utc(moment.duration(logEnd.diff(logStart)).as('milliseconds'));
    const format = 'YYYY-MM-DD HH:mm:ss:SSS';

    const logInfo = [
      `${logStartFormatted} [Info] buildTime: ${this._api.buildInfo.buildTime} buildVersion: ${this._api.buildInfo.buildHash
      } runtime: ${runtime.format('HH:mm:ss:SSS')}, started: ${logStart.format(
        format,
      )} stopped: ${logEnd.format(format)} \r\n`,
      `${logStartFormatted} [Info] Performance Info: ${CircularJSON.stringify(
        this._api.performanceInfo.info,
      )}  \r\n`,
      `${logStartFormatted} [Info] Performance Entries: ${JSON.stringify(
        this._api.performanceInfo.entries,
      )}  \r\n`,
      `${logStartFormatted} [Info] tabInstanceId: ${this._api.instanceId} (Pos ${this._api.instancePosition}) tabInstanceList: ${this._api.instanceList.join(',')} \r\n`,
      `${logStartFormatted} [Info] vendor: ${navigator.vendor}, appCodeName: ${navigator.appCodeName}, appName: ${navigator.appName}, appVersion: ${navigator.appVersion}, userAgent: ${navigator.userAgent}, language: ${navigator.language}, languages: ${navigator.languages}, platform:${navigator.platform}, cookieEnabled: ${navigator.cookieEnabled} \r\n`,
      `${logStartFormatted} [Info] locale: ${i13n.locale}, timezone: ${i13n.timezone} \r\n`,
      `${logStartFormatted} [Info] screen width: ${screen.width}, height: ${screen.height}, availableWidth: ${screen.availWidth} availableHeight: ${screen.availHeight} \r\n`,
    ];
    try {
      FileSaver.saveAs(
        new Blob([...logInfo, ...this._firstLog, ...this._rotationLog], {
          type: 'text/plain;charset=utf-8',
        }),
        'professionalClient.log',
      );
    } catch (e) {
      console.error('Could not download logfile');
      console.error(e);
    }

    return true;
  }

  log(message: string, relatedInfo: any[] | {}, level: LogLevel, scope: string) {
    const logTime = moment().format('DD.MM HH:mm:ss.SSS');
    const firstLogLength = this._firstLog.length;

    const logMessage = `${logTime} [${level}] ${scope} ${message ? message : ''
    } ${CircularJSON.stringify(relatedInfo)}\r\n`;

    if (firstLogLength < this._firstLogSize) {
      this._firstLog.push(logMessage);
    }
  }

  registerKeyCombination() {
    document.addEventListener(
      'keydown',
      (e: KeyboardEvent) => {
        if (e.keyCode === 75 /*K*/ && e.ctrlKey && e.shiftKey && e.altKey) {
          this.downloadLog();
        }
      },
      false,
    );
  }

  setApi(api: MultichannelSdk) {
    this._api = api;

    this._logger = this._api.debug('api.logger');
  }

  persist() {
    Storage.set(this._storageKey, {firstLog: this._firstLog, rotationLog: this._rotationLog}, 'logger');
  }

  restore() {
    try {
      const {firstLog, rotationLog} = Storage.getAndRemove(this._storageKey, 'logger');
      this._firstLog = firstLog;
      this._rotationLog = rotationLog;
    } catch {
      this._logger.trace('Couldn\'t restore logger data');
    }
  }
}
